import React from "react"
import Layout from "../layout/GeneralLayout"
import {
  Box,
  Heading,
  // Text,
  Paragraph,
  Anchor,
  GlassBox,
} from "../components/commonElements"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import RehypeReact from "rehype-react"
import Footer from "../components/footer"
import SEO from "../components/seo"
// const ParagraphLine = props => {
//   return <Paragraph>{props.children}</Paragraph>
// }

// const BoxG = styled(Box)`
//   font-size: 2rem;
// `

// const BoxC = props => {
//   return <BoxG>{props.children}</BoxG>
// }

const Container = styled(GlassBox)`
  font-size: 1.8rem;
  max-width: ${props => `calc(${props.theme.containerSize} - 2rem)`};
  width: calc(100% - 2rem);
  min-height: 78vh;
  @media ${props => props.theme.device.tablet} {
    min-height: 83vh;
  }
  /* overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px !important;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.white} !important;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.scrollbar} !important;
  }
`

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h1: props => <Heading {...props} level={1} />,
    h2: props => <Heading {...props} level={2} mt="3rem" />,
    h3: props => <Heading {...props} level={3} mt="2rem" />,
    h4: props => <Heading {...props} level={4} mt="1rem" />,
    p: props => <Paragraph>{props.children}</Paragraph>,
    div: props => <Box>{props.children}</Box>,
    a: Anchor,
  },
}).Compiler

// const Container = styled(Box)`
//   & .img {
//     border: 3px solid ${props => props.theme.global.colors.primary};
//   }
// `

const About = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <Box
        // maxWidth="120rem"
        alignItems="center"
        // width="100%"
        gap="1rem"
        mt="1rem"
      >
        <Container
          backgroundColor="white"
          padding="2rem"
          //   height="83vh"
        >
          {renderAst(data.file.childMarkdownRemark.htmlAst)}
        </Container>
        <Footer />
      </Box>
      {/* <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        <Markdown>{data.markdownRemark.rawMarkdownBody}</Markdown>
      </div> */}
    </Layout>
  )
}

export default About

const query = graphql`
  query {
    file(relativePath: { eq: "About.md" }) {
      childMarkdownRemark {
        htmlAst
      }
    }
  }
`

export const Head = () => (
  <SEO
    titleP="About | FlipSimu"
    descriptionP="This is the about page of FlipSimu"
    pathname="/about/"
  />
)
